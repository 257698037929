@import './variables.scss';

@mixin heading($type) {
  @if $type == 1 {
    @include headingText(52px, 64px);
  } @else if $type == 2 {
    @include headingText(40px, 52px);
  } @else if $type == 3 {
    @include headingText(32px, 44px);
  } @else if $type == 4 {
    @include headingText(24px, 40px);
  } @else if $type == 5 {
    @include headingText(20px, 32px);
  } @else if $type == 6 {
    @include headingText(18px, 28px);
  } @else if $type == 7 {
    @include headingText(16px, 28px);
  } @else if $type == 8 {
    @include headingText(14px, 22px);
  } @else if $type == 9 {
    @include headingText(12px, 16px);
  } @else {
    @include headingText(14px, 20px);
  }
}

@mixin text($type) {
  @if $type == 1 {
    @include bodyText(16px, 28px);
  } @else if $type == 2 {
    @include bodyText(14px, 22px);
  } @else if $type == 3 {
    @include bodyText(14px, 20px);
  } @else if $type == 4 {
    @include bodyText(12px, 16px);
  } @else {
    @include bodyText(14px, 20px);
  }
}

@mixin headingText($font, $line) {
  color: $textTitleColor;
  font-weight: bold;
  font-size: $font;
  line-height: $line;
}

@mixin bodyText($font, $line) {
  color: $textColor;
  font-weight: 400;
  font-size: $font;
  line-height: $line;
}

@mixin no-select {
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  &:focus {
    outline: none !important;
  }
}
