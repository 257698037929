@import '../../_variables.scss';

.connectProvider {
  background-color: transparent;
  box-shadow: none;
  font-weight: 600;
  border: 1px solid $accentColor;
  margin-top: 10px;
  padding: 15px 20px 15px 55px;
  text-align: left;
  position: relative;

  &:hover {
    background-color: transparent;
    border-color: #FFF;
  }

  &:before {
    display: block;
    content: '';
    background-size: cover;
    position: absolute;
    top: 50%;
    left: 13px;
    width: 30px;
    height: 30px;
    margin-top: -15px;
  }

  &.metaMask:before {
    left: 11px;
    width: 34px;
    height: 34px;
    background-image: url(/images/icons/metamask.png);
    margin-top: -17px;
  }

  &.coinbaseWallet:before {
    background-image: url(/images/icons/coinbase.png);
  }

  &.walletConnect:before {
    background-image: url(/images/icons/walletconnect.png);
  }
}