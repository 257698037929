@import '../../_variables.scss';

.connectButton {
  padding: 12px 30px 12px 62px;
  position: relative;

  &.withoutIcon {
    padding-left: 30px;
  }

  &:not(.withoutIcon):before {
    content: '';
    display: block;
    position: absolute;
    left: 30px;
    top: 50%;
    transform: translateY(-50%);
    width: 20px;
    height: 17px;
    cursor: pointer;
    background-color: #ffffff;
    mask: url(/images/icons/wallet.svg) no-repeat center;
    mask-size: cover;
  }

  &.authorized {
    background-color: #353556;
    padding-left: 54px;
    padding-right: 48px;

    &.accented {
      background-color: $accentColor;
    }

    &:before {
      left: 24px;
    }

    &:after {
      content: '';
      display: block;
      position: absolute;
      right: 14px;
      top: 50%;
      transform: translateY(-50%);
      width: 10px;
      height: 10px;
      cursor: pointer;
      background-color: #ffffff;
      mask: url(/images/icons/cross.svg) no-repeat center;
      mask-size: cover;
    }
  }
}