@import '../../_variables.scss';
@import '../../_mixins.scss';

.header {
  display: flex;
  align-items: center;
  position: sticky;
  top: 0;
  z-index: 99;
  width: 100%;
  height: 80px;

  @media all and (max-width: $break-point-sm) {
    background: linear-gradient(180deg, $mainBackground 0%, rgba(53, 53, 86, 0) 100%);
  }

  &.isSticky {
    background-color: $mainBackground;
  }

  .headerContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: transparent;
    transition: all 0.2s linear;
    
    @media all and (max-width: $break-point-sm) {
      flex-wrap: wrap;

      &.withCenter {
        padding-top: $spacingBasePlus;
      }
    }

    .block {
      display: flex;
      align-items: center;
      min-width: 24px;
      height: 100%;
      min-width: 182px;

      &:last-child {
        justify-content: flex-end;
      }

      .logoLink {
        cursor: pointer;
      }

      @media all and (max-width: $break-point-sm) {
        min-width: auto;

        &.left {
          order: 1;
        }

        &.right {
          order: 2;
        }
      
        &.center {
          width: 100%;
          order: 3;
          margin-top: $spacingBasePlus;
          padding-bottom: 20px;
        }
      }
    }

    .icon {
      display: flex;
      cursor: pointer;
      @include no-select;
    }

    .action {
      font-weight: 600;
      color: $primaryColor;
      font-size: $fontSizeSmall;
      line-height: 214%;
      display: flex;
      align-items: center;
      text-align: center;
      text-transform: uppercase;
      cursor: pointer;
      width: 100%;
      height: 100%;
    }

    .title {
      color: $secondaryColor;
      font-weight: 600;
      font-size: $fontSizeTitle;
      line-height: 167%;
      display: flex;
      align-items: center;
      text-align: center;
    }

    .tabsView {
      margin-bottom: -30px;
    }
  }
}

.app.withNotch {
  .headerContainer {
    padding-top: 50px;

    .tabsView {
      margin-bottom: 0;
    }
  }
}