@import '../_variables.scss';

.notFoundPage {
  background-color: $mainBackground;
  background-image: url('/images/connect/connect_bg.jpg');
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: top center;
  padding-bottom: 116px;
  min-height: 100vh;
  position: relative;

  @media 
    (-webkit-min-device-pixel-ratio: 2), 
    (min-resolution: 192dpi) {
    background-image: url('/images/connect/connect_bg@2x.jpg');
  }

  .notFoundContainer {
    max-width: 440px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    margin-top: -60px;
    text-align: center;

    button {
      margin: 0 auto;
    }

  }
}